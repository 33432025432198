/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../constants'
import { announcementListSchema } from '../middlewares/normalizr'
import { retrieveURL } from '@vidday/utils'
import { addFlashMessage } from '../../app/behaviors/flash-messages'
import { history } from '../../../history'

/* ******************************
 * FETCH ALL ANNOUNCEMENTS
 ***************************** */

export const fetchAnnouncements = () => ({
	[RSAA]: {
		types: [
			types.LOAD_ADMIN_ANNOUNCEMENTS_REQUEST,
			{
				type: types.LOAD_ADMIN_ANNOUNCEMENTS_SUCCESS,
				payload: (action, state, res) => {
					return getJSON(res).then((json) => normalize(json.data, announcementListSchema))
				},
			},
			types.LOAD_ADMIN_ANNOUNCEMENTS_FAILURE,
		],
		endpoint: retrieveURL() + '/api/v2/announcements',
		credentials: 'include',
		method: 'GET',
	},
})

/* ******************************
 * CREATE AN ANNOUNCEMENT
 ***************************** */

export function addAnnouncement(data) {
	// sanitize request data
	const payload = {
		enabled: data.enabled,
		starts: data.starts,
		ends: data.ends,
		message: data.message,
		type: data.type,
	}

	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.ADD_ADMIN_ANNOUNCEMENT_REQUEST,
					{
						type: types.ADD_ADMIN_ANNOUNCEMENT_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', 'Announcement Created Successfully.'))
								// dispatch(push('/admin/announcements/'))
								history.push('/admin/announcements')
								return json
							})
						},
					},
					{
						type: types.ADD_ADMIN_ANNOUNCEMENT_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', 'Announcement Creation Failed.'))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/announcements',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(payload),
			},
		})
	}
}

/* ******************************
 * UPDATE A ANNOUNCEMENT
 ***************************** */

export function updateAnnouncement(data) {
	// sanitize request data
	const payload = {
		enabled: data.enabled,
		starts: data.starts,
		ends: data.ends,
		message: data.message,
		type: data.type,
	}

	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.UPDATE_ADMIN_ANNOUNCEMENT_REQUEST,
					{
						type: types.UPDATE_ADMIN_ANNOUNCEMENT_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', 'Announcement Updated Successfully.'))
								history.push('/admin/announcements')
								// dispatch(push('/admin/announcements/'))
								return json
							})
						},
					},
					{
						type: types.UPDATE_ADMIN_ANNOUNCEMENT_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', 'Announcement Update Failed.'))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/announcements/' + data.id,
				method: 'PUT',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(payload),
			},
		})
	}
}

/* ******************************
 * DELETE A ANNOUNCEMENT
 ***************************** */

export function deleteAnnouncement(id) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.DELETE_ADMIN_ANNOUNCEMENT_REQUEST,
					{
						type: types.DELETE_ADMIN_ANNOUNCEMENT_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', 'Announcement Deleted Successfully.'))
								history.push('/admin/announcements')
								// dispatch(push('/admin/announcements/'))
								return json
							})
						},
					},
					{
						type: types.DELETE_ADMIN_ANNOUNCEMENT_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', 'Announcment Delete Failed.'))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/announcements/' + id,
				method: 'DELETE',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
			},
		})
	}
}
