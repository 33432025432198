/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'
import qs from 'qs'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */
import * as types from '../constants'
import { promotionListSchema } from '../middlewares/normalizr'
import { addFlashMessage } from '../../app/behaviors/flash-messages'
import { retrieveURL } from '@vidday/utils'
import { history } from '../../../history'

/* ******************************
 * FETCH ALL PROMOTIONS
 ***************************** */

export const fetchPromotions = (queryParams) => ({
	[RSAA]: {
		types: [
			types.LOAD_ADMIN_PROMOTIONS_REQUEST,
			{
				type: types.LOAD_ADMIN_PROMOTIONS_SUCCESS,
				payload: (action, state, res) => {
					return getJSON(res).then((json) => {
						return {
							// all of this is assuming 0-based indexing...
							lastPage: json.lastPage,
							nextPage: json.nextPage,
							prevPage: json.prevPage,
							pages: json.lastPage + 1,
							count: json.count, // total results
							normalizr: normalize(json.data, promotionListSchema),
						}
					})
				},
			},
			types.LOAD_ADMIN_PROMOTIONS_FAILURE,
		],
		endpoint: retrieveURL() + '/api/v2/promo-codes?' + qs.stringify(queryParams),
		credentials: 'include',
		method: 'GET',
	},
})

/* ******************************
 * CREATE A PROMOTION
 ***************************** */

export function addPromotion(data) {
	// sanitize request data
	const payload = {
		code: data.code,
		type: data.type,
		value: data.value,
		isActive: data.isActive,
		expires: data.expires,
		maxCount: data.maxCount,
	}

	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.ADD_ADMIN_PROMOTION_REQUEST,
					{
						type: types.ADD_ADMIN_PROMOTION_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (res.status === 201) {
									dispatch(addFlashMessage('info', json.message))
								} else {
									dispatch(addFlashMessage('success', json.message))
									history.push('/admin/promotions')
									// dispatch(push('/admin/promotions/'))
								}
								return json
							})
						},
					},
					{
						type: types.ADD_ADMIN_PROMOTION_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/promo-codes',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(payload),
			},
		})
	}
}

/* ******************************
 * UPDATE A PROMOTION
 ***************************** */

export function updatePromotion(data) {
	// sanitize request data
	const payload = {
		type: data.type,
		value: data.value,
		isActive: data.isActive,
		expires: data.expires,
		maxCount: data.maxCount,
	}

	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.UPDATE_ADMIN_PROMOTION_REQUEST,
					{
						type: types.UPDATE_ADMIN_PROMOTION_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					{
						type: types.UPDATE_ADMIN_PROMOTION_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/promo-codes/' + data.id,
				method: 'PUT',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(payload),
			},
		})
	}
}

/* ******************************
 * DELETE A PROMOTION
 ***************************** */

export function deletePromotion(id) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.DELETE_ADMIN_PROMOTION_REQUEST,
					{
						type: types.DELETE_ADMIN_PROMOTION_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					{
						type: types.DELETE_ADMIN_PROMOTION_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/v2/promo-codes/' + id,
				method: 'DELETE',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
			},
		})
	}
}
